$(document).foundation()

# Link scroll to position
$('a[href*=#]:not([href=#])').click ->
    if location.pathname.replace(/^\//, '') == @pathname.replace(/^\//, '') and location.hostname == @hostname
        target = $(@hash)
        target = if target.length then target else $('[name=' + @hash.slice(1) + ']')
        if target.length
            $('html,body').animate { scrollTop: target.offset().top }, 1000
        return false

$(document.body).on 'close.fndtn.clearing', (event) ->
  $(this).find('.clearing-thumbs li').removeClass('fix-height')

$('img').removeAttr('title')